import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { formatMoney } from '../../../functions/utils';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#ff6600',
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableCellHeaderRow = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#c6c6c6',
        // color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: '#c6c6c6',
        fontSize: 16,
    },
}));


function ProfitabilityTable({ report_data, loading }) {
    const [tableLoading, setTableLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        setTableLoading(loading)
    }, [loading]);

    React.useEffect(() => {
        const rowsList = []

        if (Object.keys(report_data).length > 0) {
            const totalNet = report_data.total_income - report_data.total_expenses - report_data.total_shorts;
            const firstRow = {
                is_header: true,
                col1: report_data.date_range,
                col_income: formatMoney(report_data.total_income),
                col_expenses: formatMoney(report_data.total_expenses),
                col_shorts: formatMoney(report_data.total_shorts),
                col_net: formatMoney(totalNet),
            };

            rowsList.push(firstRow);

            // Income Items
            const sortedIncomeLines = report_data.table_data.income_lines.sort((a, b) => {
                const preferredRank = { fuel: 0, shop: 1, vas: 2 };
                return preferredRank[a.description] - preferredRank[b.description];
            });
            for (const item of sortedIncomeLines) {
                const label = 'vas' === item.description
                    ? 'VAS'
                    : item.description.charAt(0).toUpperCase() + item.description.slice(1);
                rowsList.push({
                    is_header: false,
                    col1: label,
                    col_income: formatMoney(item.amount),
                    col_expenses: null,
                    col_shorts: null,
                    col_net: null,
                });
            }

            // Expense Items
            for (const item of report_data.table_data.expenses_lines) {
                rowsList.push({
                    is_header: false,
                    col1: item.description,
                    col_income: null,
                    col_expenses: item.amount === -1 ? 'Coming-Soon' : formatMoney(item.amount),
                    col_shorts: null,
                    col_net: null,
                });
            }

            // Shorts Items
            for (const item of report_data.table_data.shorts_lines) {
                rowsList.push({
                    is_header: false,
                    col1: item.description,
                    col_income: null,
                    col_expenses: null,
                    col_shorts: item.amount === -1 ? 'Coming-Soon' : formatMoney(item.amount),
                    col_net: null,
                });
            }

            setRows(rowsList);
        }
    }, [report_data]);

    // React.useEffect(() => {
    //     console.log('TABLE-DATA: ', tableData)
    // }, [tableData]);

    return (
        <div>
        {
            !tableLoading ? (
                <TableContainer component={Paper}>
                    <Grid size={10}>
                        <Typography component="div" variant="h4" align="center">
                            POS vs Merchant Data
                        </Typography>
                    </Grid>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ borderRight: 1, borderColor: 'divider' }}></StyledTableCell>
                                <StyledTableCell align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>INCOME</StyledTableCell>
                                <StyledTableCell align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>EXPENSES</StyledTableCell>
                                <StyledTableCell align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>SHORT</StyledTableCell>
                                <StyledTableCell align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>NET</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            row.is_header ? (
                                <TableRow
                                    key={rows.indexOf(row)}
                                >
                                    <StyledTableCellHeaderRow component="th" scope="row" sx={{ borderRight: 1, borderColor: 'divider' }}>
                                        {row.col1}
                                    </StyledTableCellHeaderRow>
                                    <StyledTableCellHeaderRow align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_income}</StyledTableCellHeaderRow>
                                    <StyledTableCellHeaderRow align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_expenses}</StyledTableCellHeaderRow>
                                    <StyledTableCellHeaderRow align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_shorts}</StyledTableCellHeaderRow>
                                    <StyledTableCellHeaderRow align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_net}</StyledTableCellHeaderRow>
                                </TableRow>
                            ) : (
                                <TableRow
                                    key={rows.indexOf(row)}
                                >
                                    <TableCell component="th" scope="row" sx={{ borderRight: 1, borderColor: 'divider' }}>
                                        {row.col1}
                                    </TableCell>
                                    <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_income}</TableCell>

                                    {/* EXPENSES FORMATING */}
                                    {row.col_expenses === 'Coming-Soon' ? (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider', backgroundColor: '#ff4747', color: 'white' }}>{row.col_expenses}</TableCell>
                                    ) : (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_expenses}</TableCell>
                                    )
                                    }

                                    {/* SHORTS FORMATING */}
                                    {row.col_shorts === 'Coming-Soon' ? (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider', backgroundColor: '#ff4747', color: 'white' }}>{row.col_shorts}</TableCell>
                                    ) : (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_shorts}</TableCell>
                                    )
                                    }

                                    <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_net}</TableCell>
                                </TableRow>
                            )
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box sx={{ width: '100%', height: '600px' }}>
                    <LinearProgress
                        sx={{
                            height: 5, // Customize height
                            marginTop: 5,
                            backgroundColor: '#ff6600', // Background track color
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: 'black', // Bar color
                            },
                        }}
                    />
                </Box>
            )
        }
        </div>
    );
}

export default ProfitabilityTable;