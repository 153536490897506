import React, { useState } from 'react';
import { Button, TextField, Container, Box, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LinearProgress from '@mui/material/LinearProgress';
import FuelLogo from '../../logo.png'

import { useMutation } from "@apollo/client";
import { LOGIN } from './gql/mutations';

function LoginPage({ setAuthToken }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [uiErrorMessage, setUiErrorMessage] = React.useState('');

    const [userLogin, { loading: loginLoading, error, data: loginGqlData }] = useMutation(LOGIN);

    React.useEffect(() => {
        if (loginGqlData !== undefined) {
            // console.log('AUTH-RESP: ', loginGqlData)

            if (loginGqlData.authLogin.token === null) {
                setUiErrorMessage(loginGqlData.authLogin.message)
                setSnackBarOpen(true)
            } else {
                localStorage.setItem('token', loginGqlData.authLogin.token)
                localStorage.setItem('itu', loginGqlData.authLogin.itu)
                setAuthToken(loginGqlData.authLogin.token)
                // window.location.replace('/dashboard/overview');
            }
        }
    }, [loginGqlData])

    const handleLogin = () => {
        if (username === '' && password === '') {
            setUiErrorMessage('Please enter your username and password')
            setSnackBarOpen(true)
        } else {
            userLogin({
                variables: {
                    input: {
                        username: username,
                        password: password,
                    }
                }
            })
        }
    };

    const handleEnterButtonPress = (event) => {
        if (event.key === 'Enter') {
            if (username === '' && password === '') {
                setUiErrorMessage('Please enter your username and password')
                setSnackBarOpen(true)
            } else {
                userLogin({
                    variables: {
                        input: {
                            username: username,
                            password: password,
                        }
                    }
                })
            }
        }
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    return (
        <div>
            <Container component="main" maxWidth="xs">
                <Card sx={{ boxShadow: 5, background: '#eaeaea', marginTop: 30 }}>
                    <CardContent sx={{textAlign: 'center'}}>
                        <Box
                            sx={{
                                // marginTop: 30,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <img 
                                    src={FuelLogo}
                                    alt="Logo"
                                    style={{ width: 250, marginRight: 16 }} // Adjust the size and spacing as needed
                                />
                            </Box>
                            {/* <Typography component="h1" variant="h5">Login</Typography> */}
                            {/* <Box sx={{ mt: 1 }}> */}
                            <Box sx={{ flexGrow: 1 }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Username"
                                    value={username}
                                    color='black'
                                    onChange={(e) => setUsername(e.target.value)}
                                    onKeyDown={handleEnterButtonPress}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Password"
                                    type="password"
                                    value={password}
                                    color='black'
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={handleEnterButtonPress}
                                />
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleLogin}
                                    sx={{ mt: 3, mb: 2 }}
                                    style={{ backgroundColor: '#ff6600', color: 'white' }}
                                >
                                    Login
                                </Button>

                                {
                                    loginLoading ? (
                                        <LinearProgress
                                            sx={{
                                                height: 5, // Customize height
                                                marginTop: 5,
                                                backgroundColor: '#ff6600', // Background track color
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'black', // Bar color
                                                },
                                            }}
                                        />
                                    ) : (
                                        <div></div>
                                    )
                                }
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={4000}
                onClose={handleSnackBarClose}
                sx={{ width: '40%' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackBarClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>Error</AlertTitle>
                    {uiErrorMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default LoginPage;
