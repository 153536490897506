import { gql } from "@apollo/client";

export const GET_WET_SALES = gql`
    query dailyWetSales($siteId: String!, $startDate: String!, $endDate: String!) {
        dailyWetSales(siteId: $siteId, startDate: $startDate, endDate: $endDate) {
            distinct_products
            daily_sales {
                date
                volume
                value

                product_breakdown {
                    product
                    total_value
                    total_volume
                }
            }

            weekly_sales {
                date
                volume
                value

                product_breakdown {
                    product
                    total_value
                    total_volume
                }
            }

            monthly_sales {
                date
                volume
                value

                product_breakdown {
                    product
                    total_value
                    total_volume
                }
            }
        }
    }
`;