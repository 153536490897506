
export const formatDate = (date) => {
    const d = new Date(date)
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

export const formatDateSast = (datetime) => {
    const [date, time, period] = datetime.split(' ')
    const d = formatDate(date)
    return `${d} ${time} ${period}`
}


/**
 * Given a number return a string representation with 2 decimal places and spaces
 * for thousands separators.
 *
 * @param {Number} number
 * @returns {string}
 */
export function formatMoney(number) {
    return Number(number).toLocaleString(
        'en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    ).replaceAll(',', ' ');
}


/**
 * Given a number return a string representation with some decimal places and spaces
 * for thousands separators.
 *
 * @param {number} number
 * @param {number} [decimals=0]
 * @returns {string}
 */
export function roundAndSpaceThousands(number, decimals) {
    if ('number' !== typeof number) {
        console.error(`Got ${typeof number} "${number}"`);
    }
    if (decimals && 'number' !== typeof decimals) {
        throw new Error(`Got invalid decimals argument of type ${typeof decimals}:`, decimals);
    }
    return Number(number).toLocaleString(
        'en-GB', {
            minimumFractionDigits: decimals ?? 0,
            maximumFractionDigits: decimals ?? 0,
        },
    ).replaceAll(',', ' ');
}