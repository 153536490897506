import React from 'react';

import { styled } from '@mui/material/styles';

import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { GET_SETTLEMENT_DATA } from './gql/query';
import { useQuery } from "@apollo/client";
import SettlementTable from './components/SettlementTable';
import { formatDate } from '../../functions/utils';

const ToggleButtonChildStyled = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: '#ff6600',
        fontWeight: 'bold'
    }
});

function ProfitabilityPage({ selected_site }) {
    const [selectedSite, setSelectedSite] = React.useState('');
    const [settlementData, setSettlementData] = React.useState({});
    // const [chartModeChild, setChartModeChild] = React.useState('daily');
    // const [profitabilityViewMode, setProfitabilityViewMode] = React.useState('table');
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);

    const [uiErrorMessage, setUiErrorMessage] = React.useState('');

    const today = new Date();
    const [startDate, setStartDate] = React.useState( dayjs(today.toISOString().split('T')[0]) );
    const [endDate, setEndDate] = React.useState( dayjs(today.toISOString().split('T')[0]) );

    const { loading: pnlDataLoading, data: settlementGqlData } = useQuery(GET_SETTLEMENT_DATA, {
        variables: {
            siteId: selected_site,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
        },
        fetchPolicy: 'no-cache', // Disable caching for this query
        skip: (
            selected_site === 'Select Site' ||
            (startDate.isAfter(endDate))
        )
    });


    React.useEffect(() => {
        if (startDate.isAfter(endDate)) {
            setUiErrorMessage('The start-date cannot be after the end-date')
            setSnackBarOpen(true)
        }
    }, [startDate, endDate])

    React.useEffect(() => {
        setSelectedSite(selected_site)
    }, [selected_site])

    React.useEffect(() => {
        if (settlementGqlData !== undefined) {
            // const chartData = settlementGqlData.settlementReport.chart_data

            // chartData.daily = chartData.daily.map(val => {
            //     val.date = formatDate(val.date)
            //     return val
            // })
            // chartData.weekly = chartData.weekly.map(val => {
            //     val.date = formatDate(val.date)
            //     return val
            // })
            // chartData.monthly = chartData.monthly.map(val => {
            //     val.date = formatDate(val.date)
            //     return val
            // })

            // settlementGqlData.settlementReport.chart_data = chartData
            setSettlementData(settlementGqlData.settlementReport)
        }
    }, [settlementGqlData])

    // const handleChartModeChange = (event, newChartModeChild) => {
    //     if (newChartModeChild !== null) {
    //         setChartModeChild(newChartModeChild);
    //     }
    // };
    // const handleProfitabilityViewModeChange = (event, newProfitabilityViewMode) => {
    //     if (newProfitabilityViewMode !== null) {
    //         setProfitabilityViewMode(newProfitabilityViewMode);
    //     }
    // };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    return (
        <div>
            {
                selectedSite !== 'Select Site' ? (
                    <Grid container spacing={3} sx={{ marginTop:15 }}>
                        {/* <Grid size={10} offset={{ sm: 2 }}>
                            <Typography component="div" variant="h4" align="center">
                                Overview Page
                            </Typography>
                        </Grid> */}

                        <Grid size={10} offset={{ sm: 2 }}>
                            <Card sx={{ boxShadow: 5, background: '#eaeaea' }}>
                                <CardContent sx={{textAlign: 'center'}}>
                                    <Box
                                        component="form"
                                        sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Start Date"
                                                value={startDate}
                                                onChange={(newDate) => {
                                                    setStartDate(newDate);
                                                }}
                                                format="YYYY-MM-DD" // Custom date format
                                                // renderInput={(params) => <TextField {...params} />}
                                            />
                                            <DatePicker
                                                label="End Date"
                                                value={endDate}
                                                onChange={(newDate) => {
                                                    setEndDate(newDate);
                                                }}
                                                format="YYYY-MM-DD" // Custom date format
                                                // renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                        {/* <Button
                                            variant="contained"
                                            size='large'
                                            onClick={handleSubmitBtnClick}
                                            style={{ backgroundColor: '#ff6600', color: 'white' }}
                                        >
                                            Submit
                                        </Button> */}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>


                        <Grid size={10} offset={{ sm: 2 }}>
                            <Card sx={{ boxShadow: 5, background: '#eaeaea' }}>
                                <CardContent sx={{textAlign: 'center'}}>
                                    <SettlementTable
                                        loading={pnlDataLoading}
                                        report_data={settlementData}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={8} sx={{ marginTop:15 }}>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <Box sx={{ width: '100%', height: '600px' }}>
                                <Typography component="div" variant="h4" align="center">
                                    Loading Sites...
                                </Typography>
                                <LinearProgress
                                    sx={{
                                        height: 5, // Customize height
                                        marginTop: 5,
                                        backgroundColor: '#ff6600', // Background track color
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'black', // Bar color
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )
            }
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={4000}
                onClose={handleSnackBarClose}
                sx={{ width: '60%' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackBarClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>Error</AlertTitle>
                    {uiErrorMessage}
                </Alert>
            </Snackbar>
            <br/>
            <br/>
            <br/>
        </div>
    );
};

export default ProfitabilityPage;