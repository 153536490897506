import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    Drawer,
    List,
    ListItem,
    ListItemText,

    CssBaseline,
    AppBar as MuiAppBar,
    Toolbar,
    // Typography,
    Divider,
    IconButton,
    ListItemButton,
    ListItemIcon
} from '@mui/material';
import {
    // Menu as MenuIcon,
    // Inbox as InboxIcon,
    // Mail as MailIcon,
    // AccountCircle as AccountCircleIcon,
    // FolderOpen as FolderOpenIcon,
    Money as MoneyIcon,
    Calculate as CalculateIcon,
    TrendingUp as TrendingUpIcon,
    Home as HomeIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    LocalGasStation as LocalGasStationIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon,
    Savings as SavingsIcon
} from '@mui/icons-material';
import { Outlet, BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';

// import Button from '@mui/material/Button';
// import HomeIcon from '@mui/icons-material/Home';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import AuthMenu from './components/AuthMenu';
import FuelLogo from '../../logo.png'

// Pages
import LoginPage from '../login_page/LoginPage';
import SalesPage from '../sales/SalesPage';
import InventoryPage from '../inventory/InventoryPage';
import CashPage from '../cash/Cash';
import OverviewPage from '../overview/Overview';
import BillingAndCreditPage from '../billing_and_credit/BillingAndCredit';
import ProfitabilityPage from '../profitability/Profitability';
import SettlementPage from '../settlement/settlement';

import { GET_SITES } from "./gql/query";
import { useQuery } from "@apollo/client";

const drawerWidth = 240;

const PATH_OVERVIEW_PAGE = '/dashboard/overview';
const PATH_PROFITABILITY_PAGE = '/dashboard/profitability';
const PATH_SETTLEMENT_PAGE = '/dashboard/settlement';
const PATH_SALES_PAGE = '/dashboard/sales';
const PATH_INVENTORY_PAGE = '/dashboard/inventory';
const PATH_CASH_PAGE = '/dashboard/cash';
const PATH_BILLING_AND_CREDIT_PAGE = '/dashboard/billing_and_credit';

function CorePage({ setAuthToken }) {
    const theme = useTheme();

    // Drawer
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    // Site Id
    const [siteId, setSiteId] = React.useState('Select Site');
    const handleSiteChange = (event) => {
        setSiteId(event.target.value);
    };

    // Test User
    const [isTestUser, ] = React.useState( localStorage.getItem('itu') );

    // Site List Data
    const [sites, setSites] = React.useState([]);
    const { loading, error, data: siteListData } = useQuery(GET_SITES);
    React.useEffect(() => {
        if (siteListData !== undefined) {
            const sortedSites = Array.from(
                siteListData.sites
            ).sort((a, b) => a.name.localeCompare(b.name));

            setSites(sortedSites);

            // Select first site
            if (sortedSites.length > 0) {
                setSiteId(sortedSites[0].id);
            }
        }
    }, [siteListData])

    // App Bar Styling
    const AppBar = styled(
        MuiAppBar, {
            shouldForwardProp: (prop) => prop !== 'open',
        })(({ theme }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: `${drawerWidth}px`,
                    transition: theme.transitions.create(['margin', 'width'], {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                },
            },
        ],
    }));

    // Drawer Header Styling
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));


    const location = useLocation();

    return (
        <Box>
            <CssBaseline />
            <AppBar
                position="fixed"
                open={open}
                style={{
                    background: '#eaeaea',
                    borderBottom: '2px solid #ff6600'
                 }}
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    borderBottom: '2px solid #FF0000'
                }}
            >
                <Toolbar>
                    {/* <IconButton
                        size='large'
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={[
                            {
                                mr: 2,
                            },
                            open && { display: 'none' },
                        ]}
                    >
                        <MenuIcon />
                    </IconButton> */}

                    {/*
                    ---------------------------------------------------------------------
                    -------------------------- TOP-LEFT ICON ----------------------------
                    ---------------------------------------------------------------------
                    */}
                    <Box sx={{ flexGrow: 1 }}>
                        <img
                            src={FuelLogo}
                            alt="Logo"
                            style={{ width: 250, marginRight: 16 }} // Adjust the size and spacing as needed
                        />
                    </Box>

                    {/*
                    ---------------------------------------------------------------------
                    -------------------------- SITE SELECTOR ----------------------------
                    ---------------------------------------------------------------------
                    */}
                    <FormControl sx={{ m: 1, minWidth: 250, flexGrow: 1 }}>
                        <Select
                            id="demo-simple-select"
                            value={siteId}
                            onChange={handleSiteChange}
                            sx={{
                                width: 350, // Set width
                                color: "#ff6600", // Set text color
                                border: "1px solid #ff6600", // Set border color
                                backgroundColor:'white',
                                '& .MuiOutlinedInput-notchedOutline': { borderColor: '#ff6600' }, // Set outline color
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#ff6600", // Hover border color
                                },
                                "& .MuiSvgIcon-root": {
                                    color: "#ff6600", // Set arrow color
                                },
                            }}
                        >
                            <MenuItem value='Select Site'>Select Site</MenuItem>
                            <Divider />

                            {sites.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.id}
                                    sx={{
                                        backgroundColor: "#ffffff", // Background color
                                        "&:hover": {
                                            backgroundColor: "#ffa76d", // Hover color
                                        },
                                        "&.Mui-selected": {
                                            backgroundColor: "#ff6600", // Selected color
                                            color: "#fff", // Selected text color
                                            "&:hover": {
                                                backgroundColor: "#ff6600", // Hover color when selected
                                            },
                                        },
                                    }}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/*
                    ---------------------------------------------------------------------
                    -------------------------- AUTH MENU ----------------------------
                    ---------------------------------------------------------------------
                    */}
                    <AuthMenu setAuthToken={setAuthToken} />
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                anchor="left"
                open={open}
                sx={{
                    width: 240,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 240,
                        boxSizing: 'border-box',
                        border: '1px solid #ff6600'
                    },
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>

                <Divider />

                <List>
                    {/* OVERVIEW */}
                    <ListItem
                        component={Link}
                        to={PATH_OVERVIEW_PAGE}
                        sx={
                            location.pathname === PATH_OVERVIEW_PAGE ? {
                                backgroundColor: "#ff6600", // Active state background color
                                color: "#ffffff", // Active text color
                                fontWeight: "bold", // Active text weight
                            } : {
                                backgroundColor: "#ffffff", // Active state background color
                                color: "#000000", // Active text color
                                fontWeight: "none", // Active text weight
                            }
                        }
                    >
                        <ListItemButton>
                            <ListItemIcon
                                sx={location.pathname === PATH_OVERVIEW_PAGE ? { color: "#ffffff" } : { color: "#000000" }}
                            >
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Overview" />
                        </ListItemButton>
                    </ListItem>

                    {/* PROFITABILITY */}
                    <ListItem
                        component={Link}
                        to={PATH_PROFITABILITY_PAGE}
                        sx={
                            location.pathname === PATH_PROFITABILITY_PAGE ? {
                                backgroundColor: "#ff6600", // Active state background color
                                color: "#ffffff", // Active text color
                                fontWeight: "bold", // Active text weight
                            } : {
                                backgroundColor: "#ffffff", // Active state background color
                                color: "#000000", // Active text color
                                fontWeight: "none", // Active text weight
                            }
                        }
                    >
                        <ListItemButton>
                            <ListItemIcon
                                sx={location.pathname === PATH_PROFITABILITY_PAGE ? { color: "#ffffff" } : { color: "#000000" }}
                            >
                                <TrendingUpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Profitability" />
                        </ListItemButton>
                    </ListItem>

                    {/* SETTLEMENT */}
                    {isTestUser === 'true' ? 
                        (<ListItem
                            component={Link}
                            to={PATH_SETTLEMENT_PAGE}
                            sx={
                                location.pathname === PATH_SETTLEMENT_PAGE ? {
                                    backgroundColor: "#ff6600", // Active state background color
                                    color: "#ffffff", // Active text color
                                    fontWeight: "bold", // Active text weight
                                } : {
                                    backgroundColor: "#ffffff", // Active state background color
                                    color: "#000000", // Active text color
                                    fontWeight: "none", // Active text weight
                                }
                            }
                        >
                            <ListItemButton>
                                <ListItemIcon
                                    sx={location.pathname === PATH_SETTLEMENT_PAGE ? { color: "#ffffff" } : { color: "#000000" }}
                                >
                                    <SavingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Settltment" />
                            </ListItemButton>
                        </ListItem>) : (<></>)}
                    {/* <ListItem
                        component={Link}
                        to={PATH_SETTLEMENT_PAGE}
                        sx={
                            location.pathname === PATH_SETTLEMENT_PAGE ? {
                                backgroundColor: "#ff6600", // Active state background color
                                color: "#ffffff", // Active text color
                                fontWeight: "bold", // Active text weight
                            } : {
                                backgroundColor: "#ffffff", // Active state background color
                                color: "#000000", // Active text color
                                fontWeight: "none", // Active text weight
                            }
                        }
                    >
                        <ListItemButton>
                            <ListItemIcon
                                sx={location.pathname === PATH_SETTLEMENT_PAGE ? { color: "#ffffff" } : { color: "#000000" }}
                            >
                                <TrendingUpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Settltment" />
                        </ListItemButton>
                    </ListItem> */}

                    {/* SALES */}
                    <ListItem
                        component={Link}
                        to={PATH_SALES_PAGE}
                        sx={
                            location.pathname === PATH_SALES_PAGE ? {
                                backgroundColor: "#ff6600", // Active state background color
                                color: "#ffffff", // Active text color
                                fontWeight: "bold", // Active text weight
                            } : {
                                backgroundColor: "#ffffff", // Active state background color
                                color: "#000000", // Active text color
                                fontWeight: "none", // Active text weight
                            }
                        }
                    >
                        <ListItemButton>
                            <ListItemIcon
                                sx={location.pathname === PATH_SALES_PAGE ? { color: "#ffffff" } : { color: "#000000" }}
                            >
                                <AccountBalanceWalletIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sales" />
                        </ListItemButton>
                    </ListItem>

                    {/* INVENTORY */}
                    <ListItem
                        component={Link}
                        to={PATH_INVENTORY_PAGE}
                        sx={
                            location.pathname === PATH_INVENTORY_PAGE ? {
                                backgroundColor: "#ff6600", // Active state background color
                                color: "#ffffff", // Active text color
                                fontWeight: "bold", // Active text weight
                            } : {
                                backgroundColor: "#ffffff", // Active state background color
                                color: "#000000", // Active text color
                                fontWeight: "none", // Active text weight
                            }
                        }
                    >
                        <ListItemButton>
                            <ListItemIcon
                                sx={location.pathname === PATH_INVENTORY_PAGE ? { color: "#ffffff" } : { color: "#000000" }}
                            >
                                <LocalGasStationIcon />
                            </ListItemIcon>
                            <ListItemText primary="Inventory" />
                        </ListItemButton>
                    </ListItem>

                    {/* CASH */}
                    <ListItem
                        component={Link}
                        to={PATH_CASH_PAGE}
                        sx={
                            location.pathname === PATH_CASH_PAGE ? {
                                backgroundColor: "#ff6600", // Active state background color
                                color: "#ffffff", // Active text color
                                fontWeight: "bold", // Active text weight
                            } : {
                                backgroundColor: "#ffffff", // Active state background color
                                color: "#000000", // Active text color
                                fontWeight: "none", // Active text weight
                            }
                        }
                    >
                        <ListItemButton>
                            <ListItemIcon
                                sx={location.pathname === PATH_CASH_PAGE ? { color: "#ffffff" } : { color: "#000000" }}
                            >
                                <MoneyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Cash" />
                        </ListItemButton>
                    </ListItem>


                    {/* BILLING AND CREDIT */}
                    <ListItem
                        component={Link}
                        to={PATH_BILLING_AND_CREDIT_PAGE}
                        sx={
                            location.pathname === PATH_BILLING_AND_CREDIT_PAGE ? {
                                backgroundColor: "#ff6600", // Active state background color
                                color: "#ffffff", // Active text color
                                fontWeight: "bold", // Active text weight
                            } : {
                                backgroundColor: "#ffffff", // Active state background color
                                color: "#000000", // Active text color
                                fontWeight: "none", // Active text weight
                            }
                        }
                    >
                        <ListItemButton>
                            <ListItemIcon
                                sx={location.pathname === PATH_BILLING_AND_CREDIT_PAGE ? { color: "#ffffff" } : { color: "#000000" }}
                            > 
                                <CalculateIcon />
                            </ListItemIcon>
                            <ListItemText primary="Billing & Credit" />
                        </ListItemButton>
                    </ListItem>
                </List>

                <Divider />
            </Drawer>

            <Box component="main">
                <Outlet />
            </Box>

            <Box component="main" sx={{ width: '90%', ml:10, mr:10 }}>
                <Routes>
                    <Route path="sales" element={<SalesPage selected_site={siteId} />} />
                    <Route path="overview" element={<OverviewPage selected_site={siteId} />} />
                    <Route path="profitability" element={<ProfitabilityPage selected_site={siteId} />} />
                    {isTestUser === 'true' ? (<Route path="settlement" element={<SettlementPage selected_site={siteId} />} />) : (<></>) }
                    <Route path="inventory" element={<InventoryPage selected_site={siteId} />} />
                    <Route path="cash" element={<CashPage selected_site={siteId} />} />
                    <Route path="billing_and_credit" element={<BillingAndCreditPage selected_site={siteId} />} />

                    {/* <Route path="" element={<BillingAndCreditPage selected_site={siteId} />} /> */}
                    <Route path="" element={<LoginPage setAuthToken={setAuthToken} />} />
                </Routes>
            </Box>
        </Box>
    );
}

export default CorePage;