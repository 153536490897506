import { useState, useEffect } from "react"

export const useChartTitle = (title, view_mode) => {
    const [chartTitle, setChartTitle] = useState(title);
    useEffect(() => {
        if (view_mode === 'daily') {
            setChartTitle('Daily Fuel Sales (Rands)');
        } else if (view_mode === 'weekly') {
            setChartTitle('Weekly Fuel Sales (Rands)');
        } else if (view_mode ===  'monthly') {
            setChartTitle('Monthly Fuel Sales (Rands)');
        }
    }, [view_mode]);
    return [chartTitle]
}

const filterChartData = (data) => {
    console.log(data);

    const f = data.map(item => {
        // const [month, year, day] = item.date.split('-')
        // item.date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        return item
    })
    // .sort((a, b) => new Date(a.date) - new Date(b.date))
    console.log(f);

    return f
}

export const useChartData = (chart_data, view_mode) => {
    const [chartXLabels, setChartXLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    // useEffect(() => {
    //     if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
    //         chart_data.daily_sales = filterChartData(chart_data.daily_sales)
    //         chart_data.weekly_sales = filterChartData(chart_data.weekly_sales)
    //         chart_data.monthly_sales = filterChartData(chart_data.monthly_sales)

    //         setData(chart_data)
    //     }
    // }, [chart_data])
    useEffect(() => {
        if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
            let selectedData;
            if (view_mode === 'daily') {
                selectedData = chart_data.daily_sales
            }
            if (view_mode === 'weekly') {
                selectedData = chart_data.weekly_sales
            }
            if (view_mode === 'monthly') {
                selectedData = chart_data.monthly_sales
            }
            setChartData(selectedData)

            const labels = selectedData.map(item => item.date)

            setChartXLabels(labels)
        }
    }, [chart_data, view_mode])
    return [chartXLabels, chartData]
}

export const useDistinctProducts = (chart_data) => {
    const [distinctProducts, setDistinctProducts] = useState([])
    useEffect(() => {
        if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
            setDistinctProducts( chart_data.distinct_products )
        }
    }, [chart_data])
    return [distinctProducts]
}

