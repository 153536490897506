import { gql } from "@apollo/client";

export const GET_SETTLEMENT_DATA = gql`
    query settlementReport($siteId: String!, $startDate: String!, $endDate: String!) {
        settlementReport(siteId: $siteId, startDate: $startDate, endDate: $endDate) {
            date_range
            
            total_pnl
            total_adjustments
            total_unbanked
            total_unexpected_income
            total_settled
            total_pending_settlement

            table_data {
                adjustment_lines {
                    description
                    amount
                }
                unbanked_lines {
                    description
                    amount
                }
                unexpected_income_lines {
                    description
                    amount
                }
                settled_lines {
                    description
                    amount
                    cash_tag
                }
                pending_settlement_lines {
                    description
                    amount
                }
            }
        }
    }
`;