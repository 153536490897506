import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { formatMoney } from '../../../functions/utils';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#ff6600',
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const CashSettlementTableCell = styled(TableCell)(({ theme }) => ({
    // [`&.${tableCellClasses.head}`]: {
    //     backgroundColor: 'green',
    //     color: theme.palette.common.white,
    //     // fontSize: 16,
    //     // fontWeight: 'bold'
    // },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 16,
        backgroundColor: '#b0d2b0',
        color: theme.palette.common.black,
    },
}));

const StyledTableCellHeaderRow = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#c6c6c6',
        // color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: '#c6c6c6',
        fontSize: 16,
    },
}));


function SettlementTable({ report_data, loading }) {
// function SettlementTable({ loading }) {
    const [tableLoading, setTableLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);

    // const [rows, setRows] = React.useState([
    //     {
    //         is_header: true,
    //         col1: '2024-01-01  TO  2024-01-16',
    //         col_pnl: formatMoney(250000),
    //         col_adjustment: formatMoney(20000),
    //         col_unbanked: formatMoney(50000),
    //         col_unexpected_income: formatMoney(50000),
    //         col_settled: formatMoney(50000),
    //         col_pending_settlement: formatMoney(220000),
    //     },
    //     {
    //         is_header: false,
    //         col1: 'Cost of Shop',
    //         col_pnl: '',
    //         col_adjustment: formatMoney(50000),
    //         col_unbanked: '',
    //         col_unexpected_income: '',
    //         col_settled: '',
    //         col_pending_settlement: '',
    //     },
    //     {
    //         is_header: false,
    //         col1: 'Supplier Payments',
    //         col_pnl: '',
    //         col_adjustment: formatMoney(30000),
    //         col_unbanked: '',
    //         col_unexpected_income: '',
    //         col_settled: '',
    //         col_pending_settlement: '',
    //     },
    //     {
    //         is_header: false,
    //         col1: 'Unbanked Cash',
    //         col_pnl: '',
    //         col_adjustment: '',
    //         col_unbanked: formatMoney(0),
    //         col_unexpected_income: '',
    //         col_settled: '',
    //         col_pending_settlement: '',
    //     },
    //     {
    //         is_header: false,
    //         col1: 'Unbanked Card',
    //         col_pnl: '',
    //         col_adjustment: '',
    //         col_unbanked: formatMoney(10000),
    //         col_unexpected_income: '',
    //         col_settled: '',
    //         col_pending_settlement: '',
    //     },
    //     {
    //         is_header: false,
    //         col1: 'Unbanked Account Customers',
    //         col_pnl: '',
    //         col_adjustment: '',
    //         col_unbanked: formatMoney(40000),
    //         col_unexpected_income: '',
    //         col_settled: '',
    //         col_pending_settlement: '',
    //     },
    //     {
    //         is_header: false,
    //         col1: 'Unexpected Income',
    //         col_pnl: '',
    //         col_adjustment: '',
    //         col_unbanked: '',
    //         col_unexpected_income: formatMoney(50000),
    //         col_settled: '',
    //         col_pending_settlement: '',
    //     },
    //     {
    //         is_header: false,
    //         cash_tag: true,
    //         col1: 'EPMM/Kazang Settlement',
    //         col_pnl: '',
    //         col_adjustment: '',
    //         col_unbanked: '',
    //         col_unexpected_income: '',
    //         col_settled: formatMoney(10000),
    //         col_pending_settlement: '',
    //     },
    //     {
    //         is_header: false,
    //         cash_tag: true,
    //         col1: 'Debonairs Tag Settlement',
    //         col_pnl: '',
    //         col_adjustment: '',
    //         col_unbanked: '',
    //         col_unexpected_income: '',
    //         col_settled: formatMoney(10000),
    //         col_pending_settlement: '',
    //     },
    //     {
    //         is_header: false,
    //         cash_tag: true,
    //         col1: 'Toilet Tag Settlement',
    //         col_pnl: '',
    //         col_adjustment: '',
    //         col_unbanked: '',
    //         col_unexpected_income: '',
    //         col_settled: formatMoney(10000),
    //         col_pending_settlement: '',
    //     },
    //     {
    //         is_header: false,
    //         col1: 'Advance',
    //         col_pnl: '',
    //         col_adjustment: '',
    //         col_unbanked: '',
    //         col_unexpected_income: '',
    //         col_settled: formatMoney(10000),
    //         col_pending_settlement: '',
    //     },
    //     {
    //         is_header: false,
    //         col1: 'On-Settlement',
    //         col_pnl: '',
    //         col_adjustment: '',
    //         col_unbanked: '',
    //         col_unexpected_income: '',
    //         col_settled: formatMoney(10000),
    //         col_pending_settlement: '',
    //     },
    // ]);

    React.useEffect(() => {
        setTableLoading(loading)
    }, [loading]);

    React.useEffect(() => {
        const rowsList = []

        if (Object.keys(report_data).length > 0) {
            // const totalNet = report_data.total_income - report_data.total_expenses - report_data.total_shorts;
            const firstRow = {
                // is_header: true,
                // col1: report_data.date_range,
                // col_pnl: formatMoney(report_data.total_pnl),
                // col_adjustment: formatMoney(report_data.total_adjustments),
                // col_shorts: formatMoney(report_data.total_shorts),
                // col_net: formatMoney(totalNet),


                is_header: true,
                col1: report_data.date_range,
                col_pnl: formatMoney(report_data.total_pnl),
                col_adjustment: formatMoney(report_data.total_adjustments),
                col_unbanked: formatMoney(report_data.total_unbanked),
                col_unexpected_income: formatMoney(report_data.total_unexpected_income),
                col_settled: formatMoney(report_data.total_settled),
                col_pending_settlement: formatMoney(report_data.total_pending_settlement),
            };

            rowsList.push(firstRow);

            // // PNL Items
            // const sortedIncomeLines = report_data.table_data.income_lines.sort((a, b) => {
            //     const preferredRank = { fuel: 0, shop: 1, vas: 2 };
            //     return preferredRank[a.description] - preferredRank[b.description];
            // });
            // for (const item of sortedIncomeLines) {
            //     // const label = 'vas' === item.description
            //     //     ? 'VAS'
            //     //     : item.description.charAt(0).toUpperCase() + item.description.slice(1);
            //     rowsList.push({
            //         is_header: false,
            //         col1: item.description,
            //         col_pnl: formatMoney(item.amount),
            //         col_adjustment: null,
            //         col_unbanked: null,
            //         col_unexpected_income: null,
            //         col_settled: null,
            //         col_pending_settlement: null,
            //     });
            // }

            // Adjustment Items
            for (const item of report_data.table_data.adjustment_lines) {
                rowsList.push({
                    is_header: false,
                    col1: item.description,
                    col_pnl: null,
                    col_adjustment: item.amount === -1 ? 'Coming-Soon' : formatMoney(item.amount),
                    col_unbanked: null,
                    col_unexpected_income: null,
                    col_settled: null,
                    col_pending_settlement: null,
                });
            }

            // Unbanked Items
            for (const item of report_data.table_data.unbanked_lines) {
                rowsList.push({
                    is_header: false,
                    col1: item.description,
                    col_pnl: null,
                    col_adjustment: null,
                    col_unbanked: item.amount === -1 ? 'Coming-Soon' : formatMoney(item.amount),
                    col_unexpected_income: null,
                    col_settled: null,
                    col_pending_settlement: null,
                });
            }

            // Unexpected-Income Items
            for (const item of report_data.table_data.unexpected_income_lines) {
                rowsList.push({
                    is_header: false,
                    col1: item.description,
                    col_pnl: null,
                    col_adjustment: null,
                    col_unbanked: null,
                    col_unexpected_income: item.amount === -1 ? 'Coming-Soon' : formatMoney(item.amount),
                    col_settled: null,
                    col_pending_settlement: null,
                });
            }

            // Settled Items
            for (const item of report_data.table_data.settled_lines) {
                rowsList.push({
                    is_header: false,
                    col1: item.description,
                    col_pnl: null,
                    cash_tag: item.cash_tag,
                    col_adjustment: null,
                    col_unbanked: null,
                    col_unexpected_income: null,
                    col_settled: item.amount === -1 ? 'Coming-Soon' : formatMoney(item.amount),
                    col_pending_settlement: null,
                });
            }

            // Pending-Settlement Items
            for (const item of report_data.table_data.pending_settlement_lines) {
                rowsList.push({
                    is_header: false,
                    col1: item.description,
                    col_pnl: null,
                    col_adjustment: null,
                    col_unbanked: null,
                    col_unexpected_income: null,
                    col_settled: null,
                    col_pending_settlement: item.amount === -1 ? 'Coming-Soon' : formatMoney(item.amount),
                });
            }

            setRows(rowsList);
        }
    }, [report_data]);

    return (
        <div>
        {
            !tableLoading ? (
                <TableContainer component={Paper}>
                    <Grid size={10}>
                        <Typography component="div" variant="h4" align="center">
                            Merchant vs Banked Data
                        </Typography>
                    </Grid>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ borderRight: 1, borderColor: 'divider' }}></StyledTableCell>
                                <StyledTableCell align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>P&L NET</StyledTableCell>
                                <StyledTableCell align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>ADUSTMENTS</StyledTableCell>
                                <StyledTableCell align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>UNBANKED</StyledTableCell>
                                <StyledTableCell align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>UNEXPECTED INCOME</StyledTableCell>
                                <StyledTableCell align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>SETTLED</StyledTableCell>
                                <StyledTableCell align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>PENDING SETTLEMENT</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            row.is_header ? (
                                <TableRow
                                    key={rows.indexOf(row)}
                                >
                                    <StyledTableCellHeaderRow component="th" scope="row" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col1}</StyledTableCellHeaderRow>
                                    <StyledTableCellHeaderRow align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_pnl}</StyledTableCellHeaderRow>
                                    <StyledTableCellHeaderRow align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_adjustment}</StyledTableCellHeaderRow>
                                    <StyledTableCellHeaderRow align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_unbanked}</StyledTableCellHeaderRow>
                                    <StyledTableCellHeaderRow align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_unexpected_income}</StyledTableCellHeaderRow>
                                    <StyledTableCellHeaderRow align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_settled}</StyledTableCellHeaderRow>
                                    <StyledTableCellHeaderRow align="center" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_pending_settlement}</StyledTableCellHeaderRow>
                                </TableRow>
                            ) : (
                                <TableRow
                                    key={rows.indexOf(row)}
                                >
                                    {/* <TableCell component="th" scope="row" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col1}</TableCell> */}
                                    {row.cash_tag === true ? (
                                        <CashSettlementTableCell component="th" scope="row" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col1}</CashSettlementTableCell>
                                    ): (
                                        <TableCell component="th" scope="row" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col1}</TableCell>
                                    )}

                                    {/* PNL FORMATING */}
                                    <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_pnl}</TableCell>

                                    {/* ADJUSTMENTS FORMATING */}
                                    {row.col_adjustment === 'Coming-Soon' ? (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider', backgroundColor: '#ff4747', color: 'white' }}>{row.col_adjustment}</TableCell>
                                    ) : (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_adjustment}</TableCell>
                                    )
                                    }

                                    {/* UNBANKED FORMATING */}
                                    {row.col_unbanked === 'Coming-Soon' ? (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider', backgroundColor: '#ff4747', color: 'white' }}>{row.col_unbanked}</TableCell>
                                    ) : (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_unbanked}</TableCell>
                                    )
                                    }

                                    {/* UNEXPECTED INCOME FORMATING */}
                                    {row.col_unexpected_income === 'Coming-Soon' ? (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider', backgroundColor: '#ff4747', color: 'white' }}>{row.col_unexpected_income}</TableCell>
                                    ) : (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_unexpected_income}</TableCell>
                                    )
                                    }

                                    {/* SETTLED FORMATING */}
                                    {row.col_settled === 'Coming-Soon' ? (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider', backgroundColor: '#ff4747', color: 'white' }}>{row.col_settled}</TableCell>
                                    ) : (
                                        <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_settled}</TableCell>
                                    )
                                    }

                                    <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_pending_settlement}</TableCell>

                                    {/* <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_unbanked}</TableCell>
                                    <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_unexpected_income}</TableCell>
                                    <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_settled}</TableCell>
                                    <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>{row.col_pending_settlement}</TableCell> */}
                                </TableRow>
                            )
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box sx={{ width: '100%', height: '600px' }}>
                    <LinearProgress
                        sx={{
                            height: 5, // Customize height
                            marginTop: 5,
                            backgroundColor: '#ff6600', // Background track color
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: 'black', // Bar color
                            },
                        }}
                    />
                </Box>
            )
        }
        </div>
    );
}

export default SettlementTable;